<template>
	<div class="bg-white">
		<el-tabs type="border-card">
			<el-tab-pane style="min-height: 70vh;">
				<span slot="label">
					<i class="el-icon-finished"></i>
					我的计划
				</span>
				<div class="flex justify-content-between">
					<button-list :btnList="planList" @btnClick="planClick" />
					<el-row>
						<el-button type="primary" icon="el-icon-plus" @click="addPlans" size="small">新建计划</el-button>
					</el-row>
				</div>
				<template>
					<div class="flex flex-wrap-wrap" v-if="PlanList.length" v-loading="loading">
						<div class="padding border-grey border-radius cursor  task-div" style="width: 24%;margin: 10px 10px 0 0;box-shadow: 0 0 5px #cdcdcd;"
							v-for="(item,index) of PlanList" :key="index" @click="planDetail(item.id,index)">
							<div style="position: absolute;top:20px;right: 10px;">
								<span>
									<svg class="icon BgIcon" aria-hidden="true" v-if="item.PlanType==1">
										<use xlink:href="#icon-dianhua1"></use>
									</svg>
									<svg class="icon BgIcon" aria-hidden="true" v-if="item.PlanType==2">
										<use xlink:href="#icon-duanxin"></use>
									</svg>
									<svg class="icon BgIcon" aria-hidden="true" v-if="item.PlanType==4">
										<use xlink:href="#icon-weixin"></use>
									</svg>
									<svg class="icon BgIcon" aria-hidden="true" v-if="item.PlanType==3">
										<use xlink:href="#icon-qq"></use>
									</svg>
								</span>
							</div>
							<div style="height: 25px;width: 100%;">
								<span class="text-bold text-lg text-black"
									style="font-size: 18px;line-height: 25px">{{item.Title}}</span>
							</div>
							<div style="height:30px;line-height: 30px;font-size: 14px;">{{item.PhoneNumber1}}</div>
							<div class="padding-top-sm">
								<span>
									<i class="el-icon-user-solid el-icon--left" />
									<span style="width: 70px;display: inline-block;">客户</span>
								</span>
								<span>
									<label v-if="item.PlanStatus!=2" class="cursor">{{item.CustomerUserName}}</label>
									<span v-else>{{item.CustomerUserName}}</span>
								</span>
							</div>
							<div class="padding-top-xs">
								<span>
									<i class="el-icon-time el-icon--left" />
									<span style="width: 70px;display: inline-block;">执行时间</span>
								</span>
								<span>
									<span>{{item.ExecutionTime | dateTimeFilter}}</span>
								</span>
								<div style="float: right;font-weight: bold;">
									<span v-if="item.PlanStatus==1" class="text-orange">未执行</span>
									<span v-if="item.PlanStatus==2" style="color: #0077AA;">执行成功</span>
									<span v-if="item.PlanStatus==3" class="text-red">执行失败</span>
								</div>
							</div>
						</div>

					</div>
					<div v-else>
						<div style="text-align: center;">
							<div class="text-black" style="margin-top: 100px;">
								<i class="iconfont icon-zanwushuju" style="font-size: 64px;"></i>
								<div>暂无数据</div>
							</div>

						</div>
					</div>
				</template>

				<template>
					<div v-if="planIndex == 4">
						<div class="width-100 margin-top">
							<div class="flex width-100 border-grey aligin-items-center">
								<span class="bg-light-gray padding-sm text-center" style="width: 18%;">时间</span>
								<span class="padding-xs margin-left">
									<span class="padding-xs margin-right border-radius bg-blue">筛选</span>
									<span class="padding-xs margin-right border-radius">不限</span>
								</span>
							</div>
							<div class="flex width-100 border-grey aligin-items-center">
								<span class="bg-light-gray padding-sm text-center" style="width: 18%;">类型</span>
								<span class="padding-xs margin-left">
									<span class="padding-xs margin-right border-radius bg-blue">筛选</span>
									<span class="padding-xs margin-right border-radius">不限</span>
								</span>
							</div>
						</div>
					</div>
				</template>
				
				
			</el-tab-pane>
			
			<!-- <el-tab-pane style="min-height: 86vh;">
				<span slot="label">
					<i class="el-icon-data-line"></i>
					公司任务
				</span>
				<div class="width-100 margin-top-sm">
					<div class="flex width-100 border-grey aligin-items-center">
						<span class="bg-light-gray padding-sm text-center" style="width: 18%;">员工/部门</span>
						<span class="padding-xs margin-left">
							<span class="padding-xs margin-right border-radius bg-blue">筛选</span>
							<span class="padding-xs margin-right border-radius">不限</span>
						</span>
					</div>
				</div>
				<div class="margin-top padding border-grey" style="min-height: 70vh;">
					<div class="flex aligin-items-center justify-content-between">
						<div class="flex flex-direction">
							<span class="text-black text-bold text-lg">员工待办任务</span>
							<span class="margin-top-sm">待完成得任务数： 0</span>
						</div>
						<div>
							<el-select v-model="value" placeholder="全部任务" size="small">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</div>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane style="min-height: 86vh;">
				<span slot="label">
					<i class="el-icon-coin"></i>
					模板
				</span>
				<div class="padding border-grey">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane label="企业模板" name="first" style="min-height: 50vh;">
							<div class="flex">
								<div class="margin-right right-border" style="width: 25%;min-height: 50vh;">
									<el-menu default-active="2" class="el-menu-vertical-demo">
										<el-submenu :index="item.value" v-for="(item, index) of EnterpriseTemplateList"
											:key="item.value">
											<template slot="title">
												<span>{{ item.label }}</span>
											</template>
											<el-menu-item-group>
												<el-menu-item v-for="(serve, res) of item.list" :key="res"
													@click="changeTemplate(serve.value)">
													<i :class="serve.icon" :style="{ color: serve.color }"></i>
													<span class="margin-left-xs">{{ serve.label }}</span>
												</el-menu-item>
											</el-menu-item-group>
										</el-submenu>
									</el-menu>
								</div>
								<div class="flex justify-content-center margin-left"
									style="width: 60%;max-width: 800px;">
									<el-form ref="form" :model="form" label-width="80px">
										<el-form-item label="标题">
											<el-input v-model="form.titel"></el-input>
										</el-form-item>
										<el-form-item label="时间">
											<el-col :span="11">
												<el-date-picker type="date" placeholder="选择日期" v-model="form.date1"
													style="width: 100%;"></el-date-picker>
											</el-col>
											<el-col class="line" :span="2">-</el-col>
											<el-col :span="11">
												<el-time-picker placeholder="选择时间" v-model="form.date2"
													style="width: 100%;"></el-time-picker>
											</el-col>
										</el-form-item>

										<el-form-item label="类型">
											<el-input v-model="form.type"></el-input>
										</el-form-item>
										<el-form-item label="发送人">
											<el-input v-model="form.name"></el-input>
										</el-form-item>
										<el-form-item label="主题">
											<el-input v-model="form.theme"></el-input>
										</el-form-item>
										<el-form-item label="附件">
											<el-input v-model="form.annex"></el-input>
										</el-form-item>
										<el-form-item label="内容">
											<editor v-model="form.desc" :initial-val="editor2Val" key-name="desc"
												@change="onAdvantageEditChange"></editor>
										</el-form-item>
									</el-form>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="个人模板" name="second" style="min-height: 50vh;">暂无可用的个人模板</el-tab-pane>
					</el-tabs>
				</div>
			</el-tab-pane> -->
		</el-tabs>
		<el-pagination background layout="total,prev, pager, next,sizes,jumper" highlight-current-row :total="total"
			:page-size="ListQuery.PageSize" :page-sizes="[10,15,20,50,100,200,500]" @current-change="pageIndexChange"
			@size-change="pageSizeChange" style="text-align: center;" />
		<customerProfile :Visible="editVisible" @closepop="closeEditPop"></customerProfile>
	</div>
</template>

<script>
	import ButtonList from '../salesManagement/components/buttonList.vue';
	import Editor from '@/components/Editor/index';
	import customerProfile from '@/components/add-plans/customerProfile.vue'
	import {
		formatTime
	} from '@/utils/util.js'
	export default {
		components: {
			ButtonList,
			Editor,
			customerProfile
		},
		data() {
			return {
				editVisible: false,
				loading:true,
				total:0,
				PlanStatusQuery: {
					CutomerUserId: 0,
					sale_PlanStatus: '1',
					PageIndex: 1,
					PageSize: 20,
					Sort: [{
						"Field": "id",
						"Type": 1
					}]
				}, //销售计划的tab  即计划状态
				planList: [{
						name: '未执行',
						value: 1
					},
					{
						name: '今天',
						value: 0
					},
					{
						name: '未来7天',
						value: 7
					},
					{
						name: '执行失败',
						value: 3
					},
					{
						name: '已执行',
						value: 2
					}
				],
				planIndex: 0,
				activeName: 'first',
				PlanList: [], //计划的数据
				Information: [], //客户的资料
				options: [{
						value: '选项1',
						label: '黄金糕'
					},
					{
						value: '选项2',
						label: '双皮奶'
					},
					{
						value: '选项3',
						label: '蚵仔煎'
					},
					{
						value: '选项4',
						label: '龙须面'
					},
					{
						value: '选项5',
						label: '北京烤鸭'
					}
				],
				value: '',
				//企业模板
				EnterpriseTemplateList: [{
						label: '初步意向，邀约见面',
						value: '1',
						list: [{
								icon: 'iconfont icon-youjian1',
								label: '第一天 邮件跟进',
								color: '#0086B3',
								value: '1-1'
							},
							{
								icon: 'iconfont icon-duanxin1',
								label: '第一天 短信提醒',
								color: '#418300',
								value: '1-2'
							},
							{
								icon: 'iconfont icon-qq',
								label: '第二天 QQ问候',
								color: '#0086B3',
								value: '1-3'
							},
							{
								icon: 'iconfont icon-duanxin1',
								label: '第二天 短信问候',
								color: '#418300',
								value: '1-4'
							},
							{
								icon: 'iconfont icon-tixing',
								label: '第三天 电话提醒',
								color: '#ff5500',
								value: '1-5'
							},
							{
								icon: 'iconfont icon-dianhua1',
								label: '第三天 电话促单',
								color: '#0086B3',
								value: '1-6'
							}
						]
					},
					{
						label: '初步见面，加深印象',
						value: '2',
						list: [{
								icon: 'iconfont icon-youjian1',
								label: '第一天 邮件跟进',
								color: '#0086B3',
								value: '1-1'
							},
							{
								icon: 'iconfont icon-duanxin1',
								label: '第一天 短信提醒',
								color: '#418300',
								value: '1-2'
							},
							{
								icon: 'iconfont icon-qq',
								label: '第二天 QQ问候',
								color: '#0086B3',
								value: '1-3'
							},
							{
								icon: 'iconfont icon-duanxin1',
								label: '第二天 短信问候',
								color: '#418300',
								value: '1-4'
							},
							{
								icon: 'iconfont icon-tixing',
								label: '第三天 电话提醒',
								color: '#ff5500',
								value: '1-5'
							},
							{
								icon: 'iconfont icon-dianhua1',
								label: '第三天 电话促单',
								color: '#0086B3',
								value: '1-6'
							}
						]
					},
					{
						label: '成交客户，稳定关系',
						value: '3',
						list: [{
								icon: 'iconfont icon-youjian1',
								label: '第一天 邮件跟进',
								color: '#0086B3',
								value: '1-1'
							},
							{
								icon: 'iconfont icon-duanxin1',
								label: '第一天 短信提醒',
								color: '#418300',
								value: '1-2'
							},
							{
								icon: 'iconfont icon-qq',
								label: '第二天 QQ问候',
								color: '#0086B3',
								value: '1-3'
							},
							{
								icon: 'iconfont icon-duanxin1',
								label: '第二天 短信问候',
								color: '#418300',
								value: '1-4'
							}
						]
					}
				],
				editor2Val: '',
				form: {
					name: '',
					titel: '',
					date1: '',
					date2: '',
					type: '',
					theme: '',
					annex: '',
					desc: ''
				},
				ListQuery: {
					PageIndex: 1,
					PageSize: 15,
					Sort: [{
						"Field": "id",
						"Type": 1
					}]
				}

			};
		},
		created() {
			this.getPlanList()
		},
		mounted() {},
		methods: {
			planClick(index) {
				this.PlanList=[]
				this.planIndex = index;
				this.ListQuery.PageIndex=1;
				this.getPlanList()
			},
			pageSizeChange(val) {
				this.ListQuery.PageIndex = 1;
				this.ListQuery.pageSize = val;
				this.getPlanList();
			},
			pageIndexChange(val) {
				this.ListQuery.PageIndex = val;
				this.getPlanList();
			},
			getPlanList() {
				let methods = ['CustomerPlanGetDayTaskList', 'CustomerPlanGetTaskListByStatus',
					'CustomerPlanGetTaskListByStatus', 'CustomerPlanGetTaskListByStatus'
				];
				methods[7] = 'CustomerPlanGetDayTaskList'
				let method = methods[this.planIndex];
				if (this.planIndex == 0) {
					this.ListQuery.Day = this.planIndex
				} else if (this.planIndex == 7) {
					this.ListQuery.Day = this.planIndex
				} else {
					this.ListQuery.sale_PlanStatus = this.planIndex
				}
				this.API[method](this.ListQuery).then(res => {
					this.loading=false
					this.PlanList = res.data.rows
					this.total = res.data.total
					
				})
			},
			planDetail(ids, index) {
				this.$Dialog.planDetails({
					planids: ids
				}).then(res => {
					this.PlanList.splice(index, 1)
				})
			},
			closeEditPop(){
				this.editVisible=false
				this.getPlanList()
			},
			handleClick(tab) {
				this.activeName = tab.name;
			},
			onAdvantageEditChange(type, html) {
				this.form[type] = html;
			},
			addPlans() {
				this.editVisible = true
			}
		}
	};
</script>

<style scoped>
	.BgIcon {
		width: 100px;
		height: 100px;
		opacity: 0.3;
	}

	.task-div {
		cursor: pointer;
	}

	.task-div:hover {
		background-color: #efefef;
	}
</style>
